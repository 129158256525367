import React from "react";

import { Image } from "react-image-and-background-image-fade";
import Badge from "../badge";
import ViewResume from "../viewResume";
import { IconContext } from "@react-icons/all-files";
import { FaTools } from "@react-icons/all-files/fa/FaTools";
import { FaHeart } from "@react-icons/all-files/fa/FaHeart";

import styles from "../../styles/homeIntro.module.scss";

export default function HomeIntro(props) {

  function getYearsBetweenDates(old, current) {
    let months = (current.getFullYear() - old.getFullYear()) * 12;
    months = months + current.getMonth() - old.getMonth();
    return Math.floor(months / 12);
  }

  const currentDate = new Date();
  const fullStackDate = new Date(2017, 6, 24); // month is zero-indexed
  const developmentDate = new Date(2014, 5, 15); // month is zero-indexed
  const fullStackYears = getYearsBetweenDates(fullStackDate, currentDate);
  const developmentYears = getYearsBetweenDates(developmentDate, currentDate);

  return (
    <div className={ styles.intro }>
      {/* LEFT */}
      <div className={ styles.intro__left}>
        <h1 className={ styles.intro__header}>
          Hey there! I'm Amy, a <b>software engineer</b> with an eye for design.
        </h1>

        {/* WHAT I DO */}
        <div className="section">
          <Badge text="What I do" icon=
            {
              <IconContext.Provider value={{ color: "#FF9A23", className: "icon__2px__down" }}>
                <FaTools />
              </IconContext.Provider>
            }
          />
          <p className="text">
            I specialize in building web applications with JavaScript and a
            number of <span className={ `link` } onClick={props.onScrollToTech} onKeyDown={props.onScrollToTech} role="link" tabIndex="0">other technologies</span>. 
            From interacting with and creating APIs to crafting user-friendly interfaces, I truly enjoy working across the stack.
          </p>
          <p className="text">
            I currently work remotely as a <b>Senior Software Engineer (Full-Stack / Cloud)</b> at <a
              href="https://www.slalom.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="link">
              Slalom
            </a> on a team dedicated to building hosted applications and reusable AWS assets for technical consultants across markets.
            Previously, I worked on my own business <a
              href="https://profitkit.io/"
              target="_blank"
              rel="noopener noreferrer"
              className="link">
              ProfitKit
              </a>, a subscription analytics and management
            platform for companies running on the payment processor Stripe.
          </p>
        </div>

        {/* WHY I DO IT */}
        <div className={ `section` }>
          <Badge text={ <span><b>Why</b> I do it</span> } icon=
            {
              <IconContext.Provider value={{ color: "#FF9A23", className: "icon__2px__down" }}>
                <FaHeart />
              </IconContext.Provider>
            }
          />
          <p className={ `text` }>
            An entrepreneurial spirit powers everything I do. I feel happiest when solving
            problems, creating (code, designs, and business strategies), and helping other
            people bring their visions to life. 
          </p>
        </div>

        {/* VIEW RESUME */}
        <ViewResume iconContextProviderProps={{ 
          value: { 
            color: "white",
            size: "18px",
            style: { margin: "0px 0px 0px 4px" }
          }
        }} />
      </div>

      {/* RIGHT */}
      <div className={ styles.intro__right}>
        {/* IMAGE */}
        <Image
          alt="Amy Sitwala Introduction"
          wrapperClassName={ styles.intro__img }
          src={ `${process.env.GATSBY_ASSETS_URL}/profile.jpg` }
          width="160px"
          height="160px"
          lazyLoad
        />

        {/* STATS */}
        <div className={ styles.intro__stats }>
          <div className={ styles.intro__stat }>
            <span>{ fullStackYears }+</span>
            <div>
              Years of Professional<br/>
              <b> Full-Stack</b> Experience
            </div>
          </div>
          <div className={ styles.intro__stat }>
            <span>{ developmentYears }+</span>
            <div>
              Years of Development<br/> Experience
            </div>
          </div>
          <div className={ styles.intro__stat }>
            <span>10+</span>
            <div>
              Projects Worked On
              <br/><p></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
