import React from "react";
import { IconContext } from "@react-icons/all-files";
import { FaDatabase } from "@react-icons/all-files/fa/FaDatabase";
import { MdLanguage } from "@react-icons/all-files/md/MdLanguage";
import { FaCode } from "@react-icons/all-files/fa/FaCode";
import { FaCodeBranch } from "@react-icons/all-files/fa/FaCodeBranch";
import { FaCloud } from "@react-icons/all-files/fa/FaCloud";
import { FaPaintBrush } from "@react-icons/all-files/fa/FaPaintBrush";
import styles from "../../styles/homeTech.module.scss";


export default function HomeTech() {
  return (
    <div>
      <h1 className={ `header` }>Technologies</h1>
      <p className={ `text` }>
        I've had the opportunity to work with several different technologies over the years &mdash;
        like Python + Django, Elm.js, and even Racket! I welcome the opportunity to work with
        cutting-edge technologies and enjoy learning about what excites other engineers. 
        These days, you can find me using the following:
      </p>

      {/* TECHNOLOGIES */}
      <div className={ styles.tech__container }>
        {/* 1 -- LANGUAGES */}
        <div className={ styles.tech }>
          <div className={ `${ styles.tech__header } label` }>
            <IconContext.Provider value={{ color: "#FF9A23", className: "icon__2px__down icon__left" }}>
              <MdLanguage />
            </IconContext.Provider>
            Languages
          </div>
          <div className={ styles.tech__body }>
            <ul>
              <li>JavaScript, TypeScript</li>
              <li>SQL</li>
              <li>GraphQL</li>
              <li>HTML</li>
              <li>CSS, SASS</li>
              <li>Terraform (learning)</li>
            </ul>
          </div>
        </div>

        {/* 2 -- BACKEND */}
        <div className={ styles.tech }>
          <div className={ `${ styles.tech__header } label` }>
            <IconContext.Provider value={{ color: "#FF9A23", className: "icon__2px__down icon__left" }}>
              <FaDatabase />
            </IconContext.Provider>
            Backend
          </div>
          <div className={ styles.tech__body }>
            <ul>
              <li>Node.js</li>
              <li>Express.js</li>
              <li>MySQL</li>
              <li>PostgreSQL</li>
              <li>DynamoDB</li>
              <li>Redis</li>
            </ul>
          </div>
        </div>

        {/* 3 -- FRONTEND */}
        <div className={ styles.tech }>
          <div className={ `${ styles.tech__header } label` }>
            <IconContext.Provider value={{ color: "#FF9A23", className: "icon__2px__down icon__left" }}>
              <FaCode />
            </IconContext.Provider>
            Frontend
          </div>
          <div className={ styles.tech__body }>
            <ul>
              <li>React.js</li>
              <li>Vue.js</li>
              <li>Redux, Vuex</li>
              <li>Material UI, Fluent UI, Amplify UI, Chakra UI, Vuetify, Semantic</li>
              {/* <li>Tailwind</li> */}
            </ul>
          </div>
        </div>

        {/* 4 -- SERVICES */}
        <div className={ styles.tech }>
          <div className={ `${ styles.tech__header } label` }>
            <IconContext.Provider value={{ color: "#FF9A23", className: "icon__2px__down icon__left" }}>
              <FaCloud />
            </IconContext.Provider>
            Services
          </div>
          <div className={ styles.tech__body }>
            <ul>
              <li>AWS (IAM, S3, Cognito, CloudFront, Route 53, Lambda, DynamoDB, Elastic Beanstalk, EC2, CloudWatch, CloudFormation, Redshift, RDS, etc.)</li>
              <li>Heroku</li>
              <li>GCP (Cloud Functions)</li>
              <li>DigitalOcean</li>
            </ul>
          </div>
        </div>

        {/* 5 -- TOOLS & LIBRARIES */}
        <div className={ styles.tech }>
          <div className={ `${ styles.tech__header } label` }>
            <IconContext.Provider value={{ color: "#FF9A23", className: "icon__2px__down icon__left" }}>
              <FaCodeBranch />
            </IconContext.Provider>
            Tools &amp; Libraries
          </div>
          <div className={ styles.tech__body }>
            <ul>
              <li>Git, Github, BitBucket, Azure DevOps</li>
              <li>Docker{/* + Kubernetes */}</li>
              <li>Serverless Framework</li>
              <li>Stripe API</li>
              <li>Apollo GraphQL (Client + Server) </li>
              <li>Gatsby.js</li>
              <li>Travis</li>
            </ul>
          </div>
        </div>

        {/* 6 -- DESIGN */}
        <div className={ styles.tech }>
          <div className={ `${ styles.tech__header } label` }>
            <IconContext.Provider value={{ color: "#FF9A23", className: "icon__2px__down icon__left" }}>
              <FaPaintBrush />
            </IconContext.Provider>
            Design
          </div>
          <div className={ styles.tech__body }>
            <ul>
              <li>Figma</li>
              <li>Adobe Photoshop + Illustrator</li>
              <li>Canva</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};